var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lg:w-9/12 mx-auto justify-center" },
    [
      !_vm.showOption
        ? _c(
            "div",
            [
              _vm._m(0),
              _c(
                "h5",
                { staticClass: "md:text-2xl text-xl font-bold text-dark" },
                [_vm._v("Financial health analysis")]
              ),
              _c("p", { staticClass: "text-grey mb-6" }, [
                _vm._v(
                  "Please select an option so that we can evaluate your credit elegibility"
                ),
              ]),
              _c(
                "div",
                { staticClass: "grid md:grid-cols-2 gap-4" },
                _vm._l(_vm.verificationOptions, function (option) {
                  return _c(
                    "div",
                    {
                      key: option.id,
                      staticClass: "md:flex items-center",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleChooseActiveOption(option.key)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "option border rounded-lg p-4",
                          class: [_vm.handleActiveclass(option.key)],
                        },
                        [
                          _c("div", { staticClass: "flex items-center" }, [
                            _c(
                              "h4",
                              {
                                staticClass: "font-bold text-dark text-xl mr-2",
                              },
                              [_vm._v(_vm._s(option.title))]
                            ),
                            option.status === _vm.dataStatus.pending
                              ? _c("div", { staticClass: "status-badge" }, [
                                  _c("p", [_vm._v("In progress")]),
                                ])
                              : _vm._e(),
                          ]),
                          _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
                            _vm._v(" " + _vm._s(option.description) + " "),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _c("Button", {
                staticClass: "mt-8",
                attrs: { width: "w-full", text: "Continue" },
                on: { click: _vm.handleSelecteMethod },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.activeOption === _vm.verification.bank
                ? _c("BankLogin", {
                    on: {
                      back: function ($event) {
                        _vm.showOption = false
                      },
                      success: function ($event) {
                        return _vm.$emit("bankAdded")
                      },
                      close: _vm.handleRejection,
                    },
                  })
                : _vm._e(),
              _vm.activeOption === _vm.verification.pdf
                ? _c("UploadStatement", {
                    on: {
                      back: function ($event) {
                        _vm.showOption = false
                      },
                      success: function ($event) {
                        return _vm.$emit("bankAdded")
                      },
                      close: _vm.handleRejection,
                    },
                  })
                : _vm._e(),
              _vm.activeOption === _vm.verification.request
                ? _c("RequestBank", {
                    attrs: {
                      accountData:
                        _vm.customerAccountSummaryData
                          .pendingDirectStatementRequest,
                    },
                    on: {
                      back: function ($event) {
                        _vm.showOption = false
                      },
                      success: function ($event) {
                        return _vm.$emit("bankAdded")
                      },
                      close: _vm.handleRejection,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.showQueryModal,
            title: "Here's what happened the last time",
          },
          on: { close: _vm.handleClose },
        },
        [
          _vm.queryResult.dataStatus === _vm.dataStatus.failed
            ? _c("div", [
                _c("div", [
                  _c("p", { staticClass: "text-xs md:text-sm" }, [
                    _vm._v(
                      " We were unable to pull data from the connected account. Please retry the same account after a few minutes or connect a different account "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "mt-4" }, [
                  _c("h4", { staticClass: "text-dark font-semibold" }, [
                    _vm._v("What you can do?"),
                  ]),
                  _c(
                    "p",
                    { staticClass: "mt-2 text-lightGrey text-xs md:text-sm" },
                    [
                      _vm._v(
                        " Please connect another bank account using any of the options to get access to a higher credit limit. "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.queryResult.dataStatus === _vm.dataStatus.available &&
          _vm.eligibleAmount === 0
            ? _c("div", [
                _c("div", [
                  _c("p", { staticClass: "text-xs md:text-sm" }, [
                    _vm._v(
                      " You qualified for " +
                        _vm._s(
                          _vm._f("formatMoney")(_vm.queryResult.eligibleAmount)
                        ) +
                        " credit and we were able to pull " +
                        _vm._s(_vm.queryResult.transactionCount) +
                        " transactions from your account. "
                    ),
                  ]),
                  _c("h4", { staticClass: "text-dark font-semibold mt-4" }, [
                    _vm._v("What you can do?"),
                  ]),
                  _c("p", { staticClass: "text-xs md:text-sm" }, [
                    _vm._v(
                      " If you have performed more transactions than the above in the past three (6) months, please reconnect your account or try another account to get access to a higher credit limit. "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.queryResult.dataStatus === _vm.dataStatus.available &&
          _vm.eligibleAmount > 0
            ? _c("div", [
                _c("div", [
                  _c("p", { staticClass: "text-xs md:text-sm" }, [
                    _vm._v(
                      " You qualified for " +
                        _vm._s(
                          _vm._f("formatMoney")(_vm.queryResult.eligibleAmount)
                        ) +
                        " credit and we were able to pull " +
                        _vm._s(_vm.queryResult.transactionCount) +
                        " transactions from your account. "
                    ),
                  ]),
                  _c("h4", { staticClass: "text-dark font-semibold mt-4" }, [
                    _vm._v("What you can do?"),
                  ]),
                  _c("p", { staticClass: "text-xs md:text-sm" }, [
                    _vm._v(
                      " If you have performed more transactions than the above in the past three(3) months, please reconnect your account or try another account to get access to a higher credit limit "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.queryResult.dataStatus === _vm.dataStatus.flagged ||
          _vm.queryResult.dataStatus === _vm.dataStatus.pendingAdminReview
            ? _c("p", { staticClass: "text-grey mt-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.queryResult.flaggedReason
                        ? _vm.queryResult.flaggedReason
                        : "There was an error getting information from your account please try again or connect another account "
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.queryResult.dataStatus === _vm.dataStatus.pending ||
          _vm.queryResult.dataStatus === _vm.dataStatus.pendingAdminReview
            ? _c("div", [
                _c("div", [
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(
                      "We are still assessing your financial health. Please check back after some time."
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("Button", {
            staticClass: "mt-6",
            attrs: { text: "Close", width: "w-full" },
            on: { click: _vm.handleClose },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "grid grid-cols-2 mb-4" }, [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("img", {
          staticClass: "h-6 cursor-pointer",
          attrs: {
            src: "https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg",
            alt: "",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }