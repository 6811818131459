<template>
  <div class="lg:w-9/12 mx-auto justify-center">
    <!-- <transition name="slideUp" mode="out-in" :key="activeOption"> -->
    <div v-if="!showOption">
      <div class="grid grid-cols-2 mb-4">
        <div class="flex items-center justify-between">
          <img
            class="h-6 cursor-pointer"
            src="https://res.cloudinary.com/zillaafrica/image/upload/v1623528889/customer/Zilla_Updated_1_rjqezm.svg"
            alt=""
          />
          <!-- <p class="text-lightGrey-2 text-sm">1 of 3</p> -->
        </div>
      </div>
      <h5 class="md:text-2xl text-xl font-bold text-dark">Financial health analysis</h5>
      <p class="text-grey mb-6">Please select an option so that we can evaluate your credit elegibility</p>
      <div class="grid md:grid-cols-2 gap-4">
        <div class="md:flex items-center" v-for="option in verificationOptions" :key="option.id"
          @click.stop="handleChooseActiveOption(option.key)">
          <div class="option border rounded-lg p-4" :class="[handleActiveclass(option.key)]">
            <div class="flex items-center">
              <h4 class="font-bold text-dark text-xl mr-2">{{ option.title }}</h4>
              <div class="status-badge" v-if="option.status === dataStatus.pending">
                <p>In progress</p>
              </div>
            </div>
            <p class="mt-2 text-sm text-grey">
              {{ option.description }}
            </p>
          </div>
        </div>
      </div>
      <Button width="w-full" class="mt-8" @click="handleSelecteMethod" text="Continue" />
    </div>
    <div v-else>
      <BankLogin v-if="activeOption === verification.bank" @back="showOption = false" @success="$emit('bankAdded')"
        @close="handleRejection" />
      <UploadStatement v-if="activeOption === verification.pdf" @back="showOption = false" @success="$emit('bankAdded')"
        @close="handleRejection" />
      <RequestBank v-if="activeOption === verification.request" @back="showOption = false" @success="$emit('bankAdded')"
        @close="handleRejection" :accountData="customerAccountSummaryData.pendingDirectStatementRequest" />
    </div>
    <!-- </transition> -->
    <Modal :display="showQueryModal" title="Here's what happened the last time" @close="handleClose">
      <!-- show this when data status fails -->
      <!-- queryResult.dataStatus === dataStatus.failed -->
      <div v-if="queryResult.dataStatus === dataStatus.failed">
        <div>
          <p class="text-xs md:text-sm">
            We were unable to pull data from the connected account. Please retry the same account after a few minutes or
            connect a different account
          </p>
        </div>
        <div class="mt-4">
          <h4 class="text-dark font-semibold">What you can do?</h4>
          <p class="mt-2 text-lightGrey text-xs md:text-sm">
            Please connect another bank account using any of the options to get access to a higher credit limit.
          </p>
        </div>
      </div>
      <!-- show this when data status is available but there's no eligible amount -->
      <!--  queryResult.dataStatus === dataStatus.available &&
          eligibleAmount === 0 -->
      <div v-if="queryResult.dataStatus === dataStatus.available && eligibleAmount === 0">
        <div>
          <p class="text-xs md:text-sm">
            You qualified for
            {{ queryResult.eligibleAmount | formatMoney }} credit and we were able to pull {{ queryResult.transactionCount
            }} transactions from your
            account.
          </p>
          <h4 class="text-dark font-semibold mt-4">What you can do?</h4>
          <p class="text-xs md:text-sm">
            If you have performed more transactions than the above in the past three (6) months, please reconnect your
            account or try another account
            to get access to a higher credit limit.
          </p>
        </div>
      </div>
      <!-- show this when data status is available and there's an eligible amount -->
      <!-- queryResult.dataStatus === dataStatus.available && eligibleAmount > 0 -->
      <div v-if="queryResult.dataStatus === dataStatus.available && eligibleAmount > 0">
        <div>
          <p class="text-xs md:text-sm">
            You qualified for
            {{ queryResult.eligibleAmount | formatMoney }} credit and we were able to pull {{ queryResult.transactionCount
            }} transactions from your
            account.
          </p>
          <h4 class="text-dark font-semibold mt-4">What you can do?</h4>
          <p class="text-xs md:text-sm">
            If you have performed more transactions than the above in the past three(3) months, please reconnect your
            account or try another account
            to get access to a higher credit limit
          </p>
        </div>
      </div>
      <!-- if data status is flagged; normally we should expect reason along side with it -->
      <p class="text-grey mt-4"
        v-if="queryResult.dataStatus === dataStatus.flagged || queryResult.dataStatus === dataStatus.pendingAdminReview">
        {{
          queryResult.flaggedReason
          ? queryResult.flaggedReason
          : `There was an error getting information from your
        account please try again or connect another account `
        }}
      </p>
      <!-- queryResult.dataStatus === dataStatus.pending -->
      <div
        v-if="queryResult.dataStatus === dataStatus.pending || queryResult.dataStatus === dataStatus.pendingAdminReview">
        <div>
          <p class="text-sm">We are still assessing your financial health. Please check back after some time.</p>
        </div>
      </div>
      <Button text="Close" width="w-full" @click="handleClose" class="mt-6" />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { Button } from "@/UI/Button";
import AnimateHeight from "@/UI/AnimateHeight";
import { Modal } from "@/UI/Modals";
import { DATA_STATUS } from "@/utils/sharedData/home";
export default {
  components: {
    Button,
    AnimateHeight,
    Modal,
    BankLogin: () => import("./BankLogin.vue"),
    UploadStatement: () => import("./UploadStatement.vue"),
    RequestBank: () => import("./RequestBank"),
  },

  data: () => ({
    dataStatus: DATA_STATUS,
    verificationOptions: [
      {
        id: 1,
        title: "Bank Statement (SMS)",
        description: "You will get an SMS to authenticate your account. Please enter the ticket number and password as required.",
        key: "request",
        status: "",
      },
      {
        id: 2,
        title: "Mobile banking",
        description:
          "You will be required to log in your mobile banking details to analyse your credit eligibility.",
        key: "bank",
        status: "",
      },
      // {
      //   id: 2,
      //   title: "Upload bank statement",
      //   description:
      //     "Upload at least 3 months account statement to enable us check your eligibilty for credit.",
      //   key: "pdf",
      // },
    ],
    verification: { bank: "bank", pdf: "pdf", request: "request" },
    activeOption: "request",
    showOption: false,
    showPrecondition: false,
    queryResult: {},
    customerAccountSummaryData: {
      pendingDirectStatementRequest: null,
      pendingDirectStatementRequest: null,
    },
  }),
  computed: {
    ...mapState({
      customerTodo: (state) => state?.dashboard?.dashboardData?.customerTodos,
      eligibleAmount: (state) => state?.userInfo?.creditQualification?.eligibilityCriteriaResult.eligibleAmount,
      bvnValid: (state) => state?.auth?.user?.identityNumberVerified,
      accountSummary: (state) => state?.userInfo?.accountSummary,

    }),
    showQueryModal() {
      return (
        this.queryResult?.dataStatus === this.dataStatus?.available ||
        this.queryResult?.dataStatus === this.dataStatus?.failed ||
        this.queryResult?.dataStatus === this.dataStatus?.pending ||
        this.queryResult?.dataStatus === this.dataStatus?.pendingAdminReview ||
        this.queryResult?.dataStatus === this.dataStatus?.flagged
      );
    },
  },
  created() {
    if (!this.bvnValid) {
      this.showAlert({
        description: `Please verify your BVN details in the "Verify your Identity" section before proceeding`,
        display: true,
        type: "error",
        title: "Sorry",
        callback: this.handleRejection,
        buttonText: "Close",
      });
    } else {
      // if (this?.customerTodo?.some((item) => item.key == "CREDIT_QUALIFICATION")) {
      //   let customerStep = this.customerTodo.find((item) => item.key === "CREDIT_QUALIFICATION");
      //   if (!customerStep.subTodos.includes("ACCOUNT_LINKING")) {
      //     // this.showBody = false;
      //     this.$emit("success");
      //   }
      //   // else {
      //   //   this.handleGetAccountSumary();
      //   // }
      // } else {
      this.handleGetAccountSumary();
      // }
    }
  },
  methods: {
    ...mapActions("loading", ["setLoading"]),
    ...mapActions("notification", ["showAlert"]),
    ...mapActions("userInfo", ["getCreditQualification", "getUserBanks", "getAccountSummary"]),
    ...mapActions("dashboard", ["showCreditQualificationStep"]),
    handleActiveclass(option) {
      return option === this.activeOption ? "border-brandPurple" : "option-border";
    },
    getExistingPrecondition() {
      this.setLoading(true);
      this.getUserBanks()
        .then(({ data }) => {
          if (data.data.length > 0) {
            this.getCreditQualification()
              .then(() => {
                // use the last bank the user added
                this.queryResult = data?.data[data?.data?.length - 1];
                this.queryResult.eligibleAmount = this.eligibleAmount;
                this.setLoading(false);
              })
              .catch(() => {
                this.setLoading(false);
              });
          } else {
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    handleGetAccountSumary() {
      this.setLoading(true);
      this.getAccountSummary()
        .then((data) => {
          if (data.data.pendingLinkedAccountRequest) {
            this.verificationOptions[1].status = data.data.pendingLinkedAccountRequest.dataStatus;
          }
          if (data.data.pendingDirectStatementRequest) {
            this.verificationOptions[0].status = data.data.pendingDirectStatementRequest.dataStatus;
          }

          if (data?.data?.lastCompletedAccountFinancialHealth) {
            this.getCreditQualification()
              .then(() => {
                // use the last bank the user added
                this.queryResult = data?.data?.lastCompletedAccountFinancialHealth;
                this.queryResult.eligibleAmount = this.eligibleAmount;
                this.setLoading(false);
              })
              .catch(() => {
                this.setLoading(false);
              });
          } else {
            this.setLoading(false);
          }
        })
        .catch(() => {
          this.setLoading(false);
        });
    },
    handleClose() {
      // if (this.queryResult?.dataStatus === this.dataStatus?.pending) {
      //   this.$emit("close");
      // }
      this.queryResult = {};
    },
    // if you ask me why I named this function like this, who I go ask too :)
    handleRejection() {
      this.$emit("close");
      // this.showCreditQualificationStep(false);
    },
    handleSelecteMethod() {
      // this.showOptions = true;
      if (
        this.activeOption === this.verification.bank &&
        this.accountSummary?.pendingLinkedAccountRequest?.dataStatus === this.dataStatus.pending
      ) {
        this.showAlert({
          display: true,
          type: "error",
          title: "Processing",
          description: "We are still assessing your finacial health. Please check back after some time",
        });

        return false;
      } else {
        this.showOption = true;
      }
    },
    handleChooseActiveOption(key) {
      if (key === this.activeOption) return false;
      this.activeOption = key;
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";

/* .options-container {
  display: flex;
  align-items: center;
} */

.option {
  height: 12rem;
  cursor: pointer;
}

.option-border {
  border: 1px solid rgba(105, 86, 200, 0.25);
}

.error-message {
  border: 1px solid #ffd5cc;
  color: #fc3c11;
  background: #fff7f5;
  padding: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

.success-credit {
  background: rgba(243, 243, 246, 0.5);
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
  color: #6b6873;
}

.status-badge {
  display: inline-block;
  background: #fffcf5;
  border: 0.5px solid #ffc130;
  border-radius: 8px;
  padding: 4px;
}

.status-badge p {
  color: #523f14;
  font-weight: 600;
  font-size: 11px;
  line-height: 120%;
}
</style>
